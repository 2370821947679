import momemt from 'moment';

jQuery( document ).ready(function($) {

  // EVENTS
  $.ajax( {
		url: 'https://rest.bandsintown.com/artists/India%20Shawn/events?app_id=45PRESS_INDIA_SHAWN',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					let location = event.venue.city + ', ' + event.venue.region;

					if ( location === ', ' ) {
						location = '';
					}

					if ( event.venue.name.toLowerCase() === 'streaming live' ) {
						location = 'Online';
					}
					console.log(event)
					html += '<div class="event">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
					html += '<div class="event-location"><span>' + location + '</span></div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn btn-border">' + offer.type + '</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

});